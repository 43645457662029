import React from 'react';
import config from 'react-global-configuration';

class Settings extends React.Component {
  constructor() {
    super();
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
  }

  state = {
    phone : localStorage.getItem('phone') || 'ios',
    tmessage : localStorage.getItem('tmessage') || 'ios'
  }

  onChangeValue(event) {
    this.setState({phone : event.target.value});
    localStorage.setItem('phone', event.target.value);
  }

  onTextChange(event) {
    localStorage.setItem('tmessage', event.target.value);
    this.setState({tmessage : event.target.value});
  }

  render() {
    console.log("During render phone is " + localStorage.getItem('phone'));
    return (
      <div className="Settings">
      <div>
        <input type="radio" value="ios" name="phone" checked={this.state.phone === "ios"} onChange={this.onChangeValue}/> iOS
        <input type="radio" value="android" name="phone" checked={this.state.phone === "android"} onChange={this.onChangeValue}/> Android
        <input type="radio" value="other" name="phone" checked={this.state.phone === "other"} onChange={this.onChangeValue}/> Other
      </div>
      <div>
        <textarea rows="5" name="name" onChange={this.onTextChange}>
        Some exciting text message!!
        </textarea>
      </div>
      </div>
    );
  }  
}

export default Settings;