import React from 'react';

class VoterSearch extends React.Component {
  constructor(props) {
    super(props);
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.constructor.name);
    this.props.handleSearch();
  }

  clear() {
    this.input.value = '';
  }

  render() {
    return (
      <div className="VoterSearch message">
        <form onSubmit={this.handleSubmit}>
          <input placeholder="Search" size={15} ref={input => this.input = input} type="text" name="name" onChange= {this.props.onInputChange}/>
        </form>
      </div>
    );
  }
}

export default VoterSearch;