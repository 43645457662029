import React from 'react';
import axios from 'axios';
import https from 'https';

class PersonNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textAreaValue: this.props.voter.note,
      editMode: false
    };
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editView = this.editView.bind(this);    
    this.updateNote = this.updateNote.bind(this);
  }

  handleKeyUp(event) {
    if (event.key === 'Enter') {
      this.setState({ textAreaValue: event.target.value.trim() });
      this.setState({ editMode: false });
      this.updateNote(this.state.textAreaValue);
    }
  }
  handleChange(event) {
    this.setState({ textAreaValue: event.target.value });
  }

  handleClick(e) {
    this.setState({ editMode: true });
  }

  updateNote(note) {
    // At instance level
    const instance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    });
    var dataUrl = window.location.protocol + "//" + window.location.host + "/saveNote";
    if (window.location.port == 3000) {
      dataUrl = "http://localhost:8080/saveNote";
    }
    dataUrl = dataUrl + "?token=" + localStorage.getItem('token');
    dataUrl = dataUrl + "&note=" + note;
    dataUrl = dataUrl + "&person=" + this.props.voter.key;
    console.log("Looking for " + dataUrl);
    instance.get(dataUrl)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log("Some exception");
        if (error.response) {
          console.log(error.response.status) // 401
          if (error.response.data)
            console.log(error.response.data.error) //Please Authenticate or whatever returned from server
          if (error.response.status == 401) {
            this.props.setToken(null);
          }
        }
      })
  }

  editView() {
    return (
      <div>
        <textarea
          className={'freeform'}
          autoFocus={true}
          value={this.state.textAreaValue}
          onKeyUp={this.handleKeyUp}
          onChange={this.handleChange}
        />
      </div>
    );
  }

  getView() {
    if (this.state.editMode)
      return this.editView();
    if (!this.state.textAreaValue || this.state.textAreaValue.length == 0)
      return <div className='faded'>Add Note</div>;
    return <div>{this.state.textAreaValue}</div>;
  }
  render() {
    return (
      <div className="PersonNote" onClick={this.handleClick}>
        {this.getView()}
      </div>
    );
  }
}

export default PersonNote;