import React from 'react';
import './PersonDetail.css';
import linkedin from './linkedins.png';
import facebook from './facebooks.png';
import ReactTags from 'react-tag-autocomplete'
import PersonNote from './PersonNote.js'
import axios from 'axios';
import https from 'https';

class PersonDetail extends React.Component {
  defPair(index, tag) {
    const p = {};
    p.id = index;
    p.name = tag;
    return p;
  }

  toPairArray(tagArray) {
    var a = [];
    if(tagArray) {
       a = tagArray.map((mytag, index) => (
         this.defPair(index, mytag)
       ));
    }
    return a;
  }
  constructor(props) {
    super(props);

     this.state = {
      tags: this.toPairArray(this.props.voter.tags),
      suggestions: [
        { id: 300, name: "Donor" },
        { id: 400, name: "Restivo Vote" },
        { id: 500, name: "Neylan Vote" },
        { id: 500, name: "MnG Andretta" },
        { id: 600, name: "Visited" },
        { id: 600, name: "Follow Up" }
      ]
    }
    this.reactTags = React.createRef()
  }

  onDelete (i) {
    this.updateTag(this.state.tags[i].name, "remove");
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1)
    this.setState({ tags })
  }

  onAddition (tag) {
    console.debug(tag);
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })
    this.updateTag(tag.name, "add");
  }

  updateTag (tag, action) {
    // At instance level
    const instance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    });
    var dataUrl = window.location.protocol + "//" + window.location.host + "/tag";
    if (window.location.port == 3000) {
      dataUrl = "http://localhost:8080/tag";
    }
    dataUrl = dataUrl + "?token=" + localStorage.getItem('token');
    dataUrl = dataUrl + "&tag=" + tag;
    dataUrl = dataUrl + "&action=" + action;
    dataUrl = dataUrl + "&person=" + this.props.voter.key;
    console.log("Looking for " + dataUrl);
    instance.get(dataUrl)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.log("Some exception");
        if (error.response) {
          console.log(error.response.status) // 401
          if (error.response.data)
            console.log(error.response.data.error) //Please Authenticate or whatever returned from server
          if (error.response.status == 401) {
            this.props.setToken(null);
          }
        }
      })
}
  dateDisplay(dateString) {

    var d = new Date(dateString);   // month and day are reverse, just year is fine
    var strDate = d.toLocaleString("en", { year: "numeric" });
    return strDate;
  }

  render() {
    return (
      <div className="PersonDetail">
        {this.props.voter.age > 0 && <div>Age : {this.props.voter.age}</div>}
        {this.props.voter.linkedin && <div className="outlinks"><a href={this.props.voter.linkedin} target="_blank"><img src={linkedin} alt="LinkedIn" /></a></div>}
        {this.props.voter.facebook && <div className="outlinks"><a href={this.props.voter.facebook} target="_blank"><img src={facebook} alt="Facebook" /></a></div>}
        <PersonNote voter={this.props.voter}/>
        {this.props.voter.home.owners && <div>House Owner : {this.props.voter.home.owners} </div>}
        {this.props.voter.home.deedDate && <div>Deed Date : {this.dateDisplay(this.props.voter.home.deedDate)} </div>}
        {this.props.voter.home.salePrice > 0 && <div>House Price : {this.props.voter.home.salePrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} </div>}
        <div>Vote Frequency: <meter id="vf" value={this.props.voter.voteFreq} min="0" max="4">{this.props.voter.voteFreq}</meter></div>
      <ReactTags
        ref={this.reactTags}
        tags={this.state.tags}
        suggestions={this.state.suggestions}
        onDelete={this.onDelete.bind(this)}
        onAddition={this.onAddition.bind(this)} 
        minQueryLength={0}
        allowNew={true}
        />
      </div>
    );
  }
}

export default PersonDetail;