import React from 'react';
import './VoterList.css';
import VoterSearch from './VoterSearch.js';
import PersonSimple from './PersonSimple.js';
import VoterMap from './VoterMap.js';
import axios from 'axios';
import https from 'https';

class VoterList extends React.Component {
  constructor(props) {
    super(props);
    this.triggerGeo = this.triggerGeo.bind(this);
    this.messageFlip = this.messageFlip.bind(this);
    this.loadPeepsRemote = this.loadPeepsRemote.bind(this);
    this.selectHome = this.selectHome.bind(this);
  }

  state = {
    search: {peeps : [], homes : [], lat: 40.638607, lng: -74.357098},
    term: "",
    dataLoad: "?",
    geoLoad: "Near Me",
    selectedHome: "None"
  }

  componentDidMount() {
    this.loadPeepsRemote();
  }

  handleGeo(position) {
    this.setState({ geoLoad: "Location Found" });
    if(this.mapref) {
      console.log("Got mapref");
      this.mapref.recenterMap(position.coords.latitude, position.coords.longitude);
    } else {
      console.log("No Mapref");
    }
    this.loadPeepsRemote(position.coords.latitude + "," + position.coords.longitude)
  }

  triggerGeo() {
    console.log("Geo Load triggered");
    this.setState({ geoLoad: " . . . " , term: ""});
    this.searchref.clear();
    if ("geolocation" in navigator) {
      this.setState({ geoLoad: "Locating" });
      navigator.geolocation.getCurrentPosition(
        success => this.handleGeo(success)
      );
    } else {
      this.setState({ geoLoad: "No geo allowed" });
    }
  }

  messageFlip() {
    this.setState({ dataLoad: "Shut" });
  }

  loadPeepsRemote(geo) {
    this.setState({ dataLoad: "Loading Data" });
    // At instance level
    const instance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    });
    var dataUrl = window.location.protocol + "//" + window.location.host + "/getPeople";
    if (window.location.port == 3000) { // Local development - no ssl and port 8080 for data
      //dataUrl = "http://10.0.0.3:8080/getPeople"; // Useful for home phone
      //dataUrl = "http://localhost:8080/getPeople";  // useful on local
      dataUrl = "http://" + window.location.hostname + ":8080/getPeople";
    }
    dataUrl = dataUrl + "?token=" + localStorage.getItem('token');
    if (geo)
      dataUrl = dataUrl + "&geo=" + geo;
    if (this.state.term)
      dataUrl = dataUrl + "&term=" + this.state.term;
    console.log("Looking for " + dataUrl);
    instance.get(dataUrl)
      .then(response => {
        this.setState({ search: response.data, dataLoad: "Loaded " + response.data.peeps.length});
        if (this.state.geoLoad === "Location Found") {
          this.setState({ geoLoad: "Near Me" })
        }
      })
      .catch(error => {
        console.log("Some exception");
        if (error.response) {
          console.log(error.response.status) // 401
          if (error.response.data)
            console.log(error.response.data.error) //Please Authenticate or whatever returned from server
          if (error.response.status == 401) {
            this.props.setToken(null);
          }
        }
      })
  }

  async onRequestFailure(err) {
    const { response } = err;
    if (response.status === 401) {
      this.props.setToken(null);
    }
    this.setState({ dataLoad: "Loading error" });
  }

  handleSearch() {
    this.loadPeepsRemote();
  }

  selectHome(key) {
    if(this.state.selectedHome == key)
      this.setState({selectedHome : "None"});
    else
      this.setState({selectedHome : key});
  };

  onInputChange = (event) => {
    this.setState({ term: event.target.value });
  }

  listView() {
    return (
      <div>
        {this.state.search.peeps.map((p, index) => (
          <PersonSimple indexNum={index} key={Math.random()} voter={p} infocus={p.home.key == this.state.selectedHome} />
        ))}
      </div>
    );
  }
  render() {
    return (
      <div className="VoterList">
        <VoterSearch ref={searchref => this.searchref = searchref} onInputChange={this.onInputChange} handleSearch={() => this.handleSearch()} />
        <div className="message" onClick={this.triggerGeo}>{this.state.geoLoad}</div>
        <div className="message">{this.state.dataLoad}</div>
        <br /><br />
        {this.props.mapview ? <VoterMap ref={mapref => this.mapref = mapref} search={this.state.search} selectedHome={this.state.selectedHome} selectHome={this.selectHome} loadPeepsRemote={this.loadPeepsRemote}/> : this.listView()}
      </div>
    );
  }
}

export default VoterList;