import React from 'react';
import './App.css';
import './PersonSimple.css';
import PersonDetail from './PersonDetail.js'
import text from './text.gif';

class PersonSimple extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  state = {
    infocus : this.props.infocus,
    phone : localStorage.getItem('phone')
  }

  handleClick(e) {
    this.setState({infocus : !this.state.infocus});
  }

  render() {
      // IOS format
    var cell = "sms://" + this.props.voter.cell + "&body=" + localStorage.getItem('tmessage') || 'Hello';
    if(this.state.phone === 'android') {
      // Android format
      cell = "sms://" + this.props.voter.cell + "?body=" + localStorage.getItem('tmessage') || 'Hello';
    }

    return (
      <div className={`PersonSimple ${(this.props.indexNum % 2 === 0) ? "evenrow" : "oddrow"} ${this.state.infocus ? "infocus" : ""}`}>
        <div className="name" onClick={this.handleClick}>{this.props.voter.first_name} {this.props.voter.last_name}</div>
        {this.props.voter.home && <div className="address">{this.props.voter.home.house_number} {this.props.voter.home.street}</div>}
        <div className={`party ${this.props.voter.party}`}>{this.props.voter.party.substring(0,1)}</div>
        {this.props.voter.cell && <div className="sms"><a href={cell}><img className="icon" src={text} alt="text" /></a></div>}
        {this.state.infocus && <div><PersonDetail voter={this.props.voter}/></div>}
      </div>

    );
  }
}

export default PersonSimple;