import React from 'react';
import './App.css';
import Dashboard from './Dashboard.js';
import VoterList from './VoterList.js';
import Settings from './Settings.js';
import Login from './Login.js';
import useToken from './useToken';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";

function logout() {
  console.log("Logging out");
  localStorage.removeItem('token');
}

function PMenuLink({ label, to, activeOnlyWhenExact=true }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });

  return (
      <li className={match ? "active" : ""}><Link to={to}>{label}</Link></li>
  );
}

function App() {
  const { token, setToken } = useToken();
  
  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <div className="main">
            <nav>
              <div id="div_top_hypers">
                <ul id="ul_top_hypers">
                  <PMenuLink to="/home" label="Home" />
                  <PMenuLink to="/" label="List" />
                  <PMenuLink to="/map" label="Map" />
                  <PMenuLink to="/settings" label="Settings" />
                  <li><a href="" onClick={logout}>X</a></li>
                </ul>
              </div>
            </nav>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/settings">
                <Settings />
              </Route>
              <Route path="/home">
                <Dashboard />
              </Route>
              <Route path="/map">
                <VoterList setToken={setToken} mapview={true}/>
              </Route>
              <Route path="/">
                <VoterList setToken={setToken} />
              </Route>
            </Switch>
          </div>
        </Router>
      </header>
    </div>
  );
}

export default App;
