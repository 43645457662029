import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import axios from 'axios';
import https from 'https';

async function loginUser(user, pw) {
    var authToken = null;

    localStorage.setItem("user", user);
    
    // At instance level
    const instance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    });
    var dataUrl = window.location.protocol + "//" + window.location.host + "/auth";
    if(window.location.port == 3000) {
      dataUrl = "http://10.0.0.3:8080/auth";
    }
    var dataUrl = window.location.protocol + "//" + window.location.host + "/auth";
    if (window.location.port == 3000) { // Local development - no ssl and port 8080 for data
      dataUrl = "http://" + window.location.hostname + ":8080/auth";
    }
    dataUrl = dataUrl + "?user=" + user + "&pw=" + pw;
    return instance.get(dataUrl)
      .then(response => {
        return response.data.token;
      })
      .catch(exception => {
        console.log(exception);
      });
  }

export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser(username, password);
    setToken(token);
  }

  return(
    <div className="login-wrapper">
      <h1>Who are you?  Log In!</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};