import React from 'react';
import './Dashboard.css';
import { RadialChart } from 'react-vis';
import VotePledge from './VotePledge.js';
import axios from 'axios';
import https from 'https';

class Dashboard extends React.Component {
  state = {
    tagData : [
      { tag : "loading", count : 1}
    ]
  }

  componentDidMount() {
    this.loadTagCounts();
  }

loadTagCounts() {
    // At instance level
    const instance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false
      })
    });
    var dataUrl = window.location.protocol + "//" + window.location.host + "/tagTotal";
    if (window.location.port == 3000) {
      dataUrl = "http://localhost:8080/tagTotal";
    }
    dataUrl = dataUrl + "?token=" + localStorage.getItem('token');
    instance.get(dataUrl)
      .then(response => {
        this.setState({ tagData: response.data});
      })
      .catch(error => {
        console.log("Some exception");
        if (error.response) {
          console.log(error.response.status) // 401
          if (error.response.data)
            console.log(error.response.data.error) //Please Authenticate or whatever returned from server
          if (error.response.status == 401) {
            this.props.setToken(null);
          }
        }
      })
  }

  tagChart() {
    return (
      <div className={'tagChart'}>
        {this.state.tagData.map((t, index) => (
          <div key={Math.random()}>
            <div className={'tagLabel'}>{t.tag}</div>
            <div className={'tagCount'}>{t.count}</div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const myData = [
      { angle: 9260, label: "Dem 9,260" },
      { angle: 9025, label: "Una 9,025" },
      { angle: 5546, label: "Rep 5,546" }];

    return (
      <div className="Dashboard">
        Welcome to PeepMine.  Thank you for checking it out and
        helping me make it into something useful.
        Feedback from you is needed to make it better so please be super picky
        and honest about what you like or don't like.
        <br/>
        <RadialChart
          className={'tagChart'}
          data={myData}
          width={300}
          height={300}
          showLabels={true}
          labelsRadiusMultiplier={1.1}
        />
        {this.tagChart()}
        <br/>
        <br/>
        <br/>
        <VotePledge/>
      </div>
    );
  }
}

export default Dashboard;