import React from 'react';
import GoogleMapReact from 'google-map-react';
import './VoterMap.css';

const VPerson = ({ text, classname }) => (
  <div className={`map_overlay ${classname}`} style={{
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10%',
    transform: 'translate(-50%, -50%)'
  }}>
    {text}
  </div>
);

const VHouse = ({ house, moreInfo, zoom }) => (
  <div className={`${moreInfo ? 'moreinfo' : ''} map_overlay`}>
    {selectLevel(house, zoom, moreInfo)}
  </div>
);

const VHouseMini = ({ house }) => (
  <div className={'mini'}>
    {house.residents.map((p, index) => (
        <div className={`${p.party} mini`}>{p.party.substring(0,1)}</div>
    ))}  
  </div>
);

const VHouseSummary = ({ house }) => (
  <div>
    <div style={{ 'whiteSpace': 'nowrap'}}>{house.address}</div>
  {house.owners && <div style={{ 'white-space': 'nowrap'}}>{house.owners.substring(0,15)}</div>}
    {house.residents.map((p, index) => (
        <div key={index} className={`party ${p.party}`}>{p.party.substring(0,1)}</div>
    ))}  
  </div>
);

const VHouseInfo = ({ house }) => (
  <div>
    <div style={{ 'white-space': 'nowrap'}}>{house.address}</div>
    {house.residents.map((p, index) => (
      <div>
        <div className="name">{p.first_name} {p.last_name}</div>
        <div className={`party ${p.party}`}>{p.party.substring(0,1)}</div>
      </div>
    ))}
  </div>
);

function selectLevel(house, zoomLevel, moreInfo) {
  if(moreInfo)
    return <VHouseInfo house={house}/>;
  if(zoomLevel > 18)
    return <VHouseSummary house={house}/>;
  return <VHouseMini house={house}/>;
}

class VoterMap extends React.Component {

state = {
  zoom : 19,
  loc : { lat : this.props.search.lat, lng : this.props.search.lng}
}

  /*
            options={map => ({ mapTypeId: map.MapTypeId.SATELLITE })}
  */

  onChildClickCallback = (key) => {
    this.props.selectHome(key);
  };

  onBoundsChangeCallback = (center, zoomLevel /* , bounds, marginBounds */) => {
    this.props.loadPeepsRemote(center[0] + "," + center[1]);
    this.setState({ zoom : zoomLevel });
  }

  recenterMap(lat, lng) {
    console.log("Recenter please " + lng + " " + lat);
    if(this.gmapRef) {
      console.log("Got map ref");
      const loc = { lat: 40.6383133, lng: -74.357154 };
      loc.lat = lat;
      loc.lng = lng;
      this.gmapRef.panTo(loc);
    }
  }

  handleApiLoaded(map, maps) {
  // use map and maps objects
    this.gmapRef = map;
  }

  render() {
    return (
      <div className="VoterMap" style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBwJrl_58LSCx0Xsc14N0nd0RVXnw3wVg4" }}
          defaultCenter={this.state.loc}
          defaultZoom={this.state.zoom}
          onChildClick={this.onChildClickCallback}
          onBoundsChange={this.onBoundsChangeCallback}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        >
          {this.props.search.homes.map((h, index) => (
            <VHouse
              key={h.key}
              lat={h.location.coordinates.values[0]}
              lng={h.location.coordinates.values[1]}
              house={h}
              moreInfo={this.props.selectedHome == h.key ? true : false}
              zoom={this.state.zoom}
            />
          ))}
        </GoogleMapReact>
      </div>
    );
  }
}

export default VoterMap;