import React from 'react';
import './VotePledge.css';

class VotePledge extends React.Component {
  render() {
    return (
      <div className="VotePledge">
        <ul id="vpledge">
          <li>M</li>
          <li>m</li>
          <li>-</li>
          <li>j</li>
          <li>J</li>
        </ul>
      </div>
    );
  }
}

export default VotePledge;